.policy-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .content-container {
        display: flex;
        flex-direction: column;
        width: 1440px;
        min-height: 719px;
        background-color: var(--c-white);
        box-sizing: border-box;
        padding: 60px 120px 100px;
        // justify-content: space-between;

        .dl-category-name {
            font-size: 20px;
            padding: 50px 0 20px;

            &:nth-of-type(1) {
                padding: 0 0 20px;
            }
        }
    }
}