.sitemap-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .content-container {
        background-color: var(--c-white);
        color: #111E18;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 1440px;
        box-sizing: border-box;
        padding: 50px 120px 0;
        // min-height: 920px;

        .title-sitemap {
            padding-bottom: 55px;
        }

        .url-lists {
            display: flex;
            flex-direction: row;


            .col-list {
                width: 400px;

                .url-title {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 130%;
                    padding-bottom: 5px;
                }

                .url-el {
                    list-style: none;
                    padding-left: 0;

                    li {
                        color: #676767;
                        font-size: 16px;
                        line-height: 130%;
                        line-height: 1.2rem;
                        padding-bottom: 15px;

                        &:last-child {
                            padding-bottom: 30px;
                        }
                    }
                }
            }
        }
    }
}