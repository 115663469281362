.hd-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .header-container {
        background-color: var(--c-primary-900);
        background-image: url('../assets/images/pattern.png');
        // color: var(--c-white);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 104px;
        height: 80px;
        width: 1440px;
        box-sizing: border-box;
        padding: 0 30px 0;

        .logo-nav-container {
            display: flex;
        }

        .main-menu,
        .client-menu {
            display: flex;
            align-items: center;
        }

        .main-menu {
            letter-spacing: 0.05em;

            .links {
                font-size: 14px;
                padding: 0 15px;
                list-style: none;

                .underLink {
                    position: relative;
                }

                .underLink:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 1px;
                    bottom: -3px;
                    left: 0;
                    background-color: var(--c-white);
                    transition: transform 0.25s ease-out;
                }

                .underLink:hover:after {
                    transform: scaleX(1);
                }
            }
        }

        .client-menu {
            font-size: 13px;
            letter-spacing: 0.06em;
            list-style: none;
            display: flex !important;

            .sell-ground {
                font-size: 14px;

                .underLink {
                    position: relative;

                }

                .underLink:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 1px;
                    bottom: -3px;
                    left: 0;
                    background-color: var(--c-white);
                    transition: transform 0.25s ease-out;
                }

                .underLink:hover:after {
                    transform: scaleX(1);
                }
            }
        }

        .buy-ground {
            // height: 48px;
            height: 41px;
            // width: 222px;
            width: 198px;
            border: 0.5px solid rgba(var(--c-primary-500), 0.3);
            border-radius: 4px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            margin-left: 40px;
            justify-content: space-between;
            padding: 0 20px;
            background-color: var(--c-primary-500);
            display: flex;
            align-items: center;
            box-sizing: border-box;
        }

        .buy-ground:hover {
            cursor: pointer;
            padding-right: 1.2em;
            transition: padding .3s, background-color 1s;
            background-color: #0E7340;
        }
    }
}

// steix http://steix.react.themesbrand.com/index_1