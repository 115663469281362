.purchasing-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .purchasing-section-container {
        position: relative;
        background-color: var(--c-white);
        color: var(--c-primary-900);
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 1440px;
        box-sizing: border-box;
        // height: 720px;

        .purchasing-left {
            width: 100%;
            height: 100%;
            // height: 800px;
            padding: 80px 80px 0;
            // border-right: 1px solid var(--c-custom-2);

            .container {
                position: sticky;
                top: 30px;
                left: 30px;
            }
        }

        .purchasing-right {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 120px 80px 0 0;

            .purchase-type {
                display: flex;
                // justify-content: space-around;
                width: 370px;
                text-transform: uppercase;

                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 100%;
                letter-spacing: 0.06em;
                color: var(--c-custom-5);

                .point {
                    width: 100%;
                    border-bottom: 1px solid var(--c-custom-6);
                    // padding-bottom: 10px;
                    padding: 0 0 15px 0;
                    display: flex;
                    justify-content: center;
                }


                .active {
                    transition: opacity 1s ease-out;
                    color: var(--c-primary-500);
                    border-bottom: 3px solid #0b5527;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .purchase-list {
                padding-top: 50px;
                padding-left: 90px;

                .purchase-name {
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 150%;
                    color: #111;
                }

                .title-hero-secondary {
                    padding: 0 0 25px 0;
                }
            }

            .buy-ground {
                height: 54px;
                width: 330px;
                border: 0.5px solid rgba(var(--c-primary-500), 0.3);
                border-radius: 4px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                justify-content: space-between;
                padding: 0 20px;
                background-color: var(--c-primary-500);
                display: flex;
                align-items: center;
                box-sizing: border-box;

                a {
                    text-decoration: none !important;
                }
            }

            .buy-ground:hover {
                cursor: pointer;
                padding-right: 1.2em;
                transition: padding .3s, background-color 1s;
                background-color: #0E7340;
            }

            .main-description {
                color: var(--c-neutral);
                line-height: 24px;
            }

            .timeline-events {
                position: absolute;
                top: 740px;

                .event {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 448px;
                    height: fit-content;
                    padding: 24px;
                    border-radius: 4px;
                    margin-top: 40px;
                    line-height: 1.2rem;

                    .point {
                        position: absolute;
                        left: -87px;
                    }

                    .point-date {
                        position: absolute;
                        // left: -140px;
                        right: 545px;
                        color: var(--c-custom-2);
                        text-align: end;
                    }

                    .photosettlement {
                        padding-bottom: 20px;
                    }
                }

                // .now {
                //     color: white;
                //     background-color: var(--c-primary-800);

                //     .link {
                //         text-decoration: none;
                //     }
                // }

                // .past {
                //     background-color: var(--c-custom-1);

                //     .link {
                //         text-decoration: none;
                //         color: var(--c-primary-500) !important;
                //     }
                // }
            }

            .dot {
                position: absolute;
                left: 0px;
                height: 56px;
                width: 56px;
                border: 1px solid var(--c-neutral-400);
                border-radius: 50%;
                background-color: var(--c-white);
                z-index: 3;

                img {
                    position: absolute;
                    left: 14px;
                    top: 14px;
                    z-index: 3;
                }

            }

            .lineY {
                position: absolute;
                left: 28px;
                top: 255px;
                height: 1065px;
                border-left: 1px solid var(--c-custom-2);
                z-index: 0;
            }
        }

        .purchasing-about {
            height: 100%;
            width: 100%;
            max-width: 450px;
            // position: sticky;
            // top: 30px;
            // left: 30px;

            .main-title {
                font-weight: 500;
                font-size: 48px;
                line-height: 125%;
            }

            .second-title {
                font-weight: 500;
                font-size: 36px;
                line-height: 125%;
            }



            .year-list {
                padding-left: 0;
                padding-top: 40px;
                margin-left: 0px;
                display: flex;
                justify-content: space-evenly;
                list-style-type: none;
                margin-bottom: 100px;
                // width: 391px;

                li {
                    cursor: pointer;
                }

                li:nth-child(even) {
                    color: var(--c-custom-4);
                }
            }
        }

        .title-hero-secondary {
            padding-top: 30px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 160%;
        }

    }
}


// background-color: var(--c-custom-1);
// https://developer.mozilla.org/en-US/docs/Web/CSS/text-decoration-thickness