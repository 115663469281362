.about-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .about-section-container {
        position: relative;
        background-color: var(--c-white);
        color: var(--c-primary-900);
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 1440px;
        box-sizing: border-box;

        .about-left {
            width: 100%;
            height: 100%;
            height: 9200px;
            background-color: var(--c-custom-1);
            padding: 80px 80px 0;
            border-right: 1px solid var(--c-custom-2);

            .container {
                position: sticky;
                top: 30px;
                left: 30px;
            }
        }

        .about-right {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 140px 80px 0;

            .main-description {
                color: var(--c-neutral);
                line-height: 24px;
            }

            .timeline-events {
                position: absolute;
                top: 740px;

                .event {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 448px;
                    height: fit-content;
                    padding: 24px;
                    border-radius: 4px;
                    margin-top: 40px;
                    line-height: 1.2rem;

                    .point {
                        position: absolute;
                        left: -87px;
                    }

                    .point-date {
                        position: absolute;
                        // left: -140px;
                        right: 545px;
                        color: var(--c-custom-2);
                        text-align: end;
                    }

                    .photosettlement {
                        padding-bottom: 20px;
                    }
                }

                .now {
                    color: white;
                    background-color: var(--c-primary-800);

                    .link {
                        text-decoration: none;
                    }
                }

                .past {
                    background-color: var(--c-custom-1);

                    .link {
                        text-decoration: none;
                        color: var(--c-primary-500) !important;
                    }
                }
            }
        }

        .description-about {
            height: 100%;
            width: 100%;
            // position: sticky;
            // top: 30px;
            // left: 30px;

            .about-img-grid {
                padding: 40px 0 80px 0;
            }

            .main-title {
                font-weight: 500;
                font-size: 48px;
                line-height: 125%;
            }

            .sticky1 {
                top: 30px;
                left: 30px;
                position: sticky !important;
            }

            .second-title {
                font-weight: 500;
                font-size: 36px;
                line-height: 125%;
            }

            .title-hero-secondary {
                padding-top: 30px;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
            }

            .year-list {
                padding-left: 0;
                padding-top: 40px;
                margin-left: 0px;
                display: flex;
                justify-content: space-evenly;
                list-style-type: none;
                margin-bottom: 100px;
                // width: 391px;

                li {
                    cursor: pointer;
                }

                li:nth-child(even) {
                    color: var(--c-custom-4);
                }
            }

            .title-hero {
                font-size: 13px;
                letter-spacing: 0.01em;
                color: var(--c-primary-500);
                text-transform: uppercase;
            }
        }

    }
}


// background-color: var(--c-custom-1);