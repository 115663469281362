    .btn-btt {
        position: fixed;
        bottom: -28px;
        width: 172px;
        height: 40px;
        background-color: #EEE;
        color: black !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 8px 20px;
        gap: 16px;
        border-radius: 8px 8px 0 0;
        border: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;

        &:hover {
            cursor: pointer;
        }

        img {
            transform: rotate(-90deg);
        }
    }

    .hidden {
        display: none !important;
    }