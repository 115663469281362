// .container {
//     height: 400px;
// }

.title-contact {
    height: 50px;
    padding-left: 20px;
    margin-bottom: 10px;
    background-color: var(--c-custom-1);
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
}

.marginasd {
    padding: 10px;
    margin: 20px;
}