.home-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .hero-section-container {
        // position: relative;
        background-color: var(--c-primary-900);
        background-image: url('../assets/images/pattern.png');
        color: var(--c-white);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1440px;
        box-sizing: border-box;
        padding: 50px 80px 0;

        .left-hs {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 500px;

            .title-hero {
                font-size: 13px;
                letter-spacing: 0.01em;
                color: #10854B;
            }

            .hero-description {
                font-size: 20px;
                line-height: 30px;
                color: rgba(255, 255, 255, 0.7);

                .white-text {
                    color: var(--c-white);
                }
            }

            .title-cta-hero {
                font-size: 56px;
                font-weight: 500;
                line-height: 115%;
                width: 60%;
                text-align: center;
            }

            .btn-check-funnel {
                display: flex;
                align-items: center;
                height: 52px;
                width: 275px;
                border: 0.5px solid rgba(var(--c-primary-500), 0.3);
                border-radius: 4px;
                justify-content: space-between;
                margin-top: 30px;
                padding: 0 20px;
                background-color: var(--c-primary-500);
                box-sizing: border-box;
                margin-bottom: 8px;

                .cta-text {
                    font-size: 14px;
                    letter-spacing: 0.06em;
                }
            }

            .rating {
                display: flex;
                align-items: center;

                .desc-rating {
                    margin-left: 5px;
                }
            }

            .btn-check-funnel:hover {
                cursor: pointer;
                padding-right: 1.2em;
                transition: padding .3s, background-color 1s;
                background-color: #0E7340;
            }

            .video-placeholder {
                position: absolute;
                top: 560px;
                z-index: 2;
            }

            .text-placeholder {
                position: absolute;
                bottom: 114px;
                width: 320px;
                height: 96px;
                left: 490px;
                display: flex;
                flex-direction: column;

                .hero-description-settlement {
                    color: rgba(255, 255, 255, 0.6);
                    font-size: 16px;
                    line-height: 160%;
                    // letter-spacing: 0.01em;
                }
            }
        }
    }
}

.settlement-slider-cont {
    display: flex;
    justify-content: center;

    .settlement-section-cont {
        // position: relative;
        background-color: var(--c-white);
        color: var(--c-primary-900);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1440px;
        box-sizing: border-box;
        min-height: 800px;
        padding-top: 300px;

        .header-title {
            color: var(--c-neutral);
            padding-bottom: 20px;
            line-height: 150%;
        }

        .settlement-selector {
            img {
                padding-right: 23px;
            }
        }

        .found-plot {
            margin: 50px 0;

            .career-container2 {
                display: flex;
                justify-content: center;
                background-color: var(--c-black);

                .career-section-container {
                    position: relative;
                    background-color: var(--c-white);
                    color: var(--c-primary-900);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    width: 1440px;
                    box-sizing: border-box;
                    padding: 80px 80px 0;
                    // height: 100%;

                    .pad {
                        padding: 0 30px;
                    }

                    .contact-title {
                        font-size: 48px;
                        margin-top: 30px;
                        margin-bottom: 50px;
                        padding-right: 180px;
                    }

                    .main-description {
                        color: var(--c-neutral);
                        line-height: 28.8px;
                        padding-right: 100px;
                    }

                    .career-img-grid {
                        padding: 0 0 0 40px;
                    }

                    .career-img-numbers {
                        padding: 20px 0 0 40px;
                    }

                    .buy-ground {
                        height: 54px;
                        width: 330px;
                        border: 0.5px solid rgba(var(--c-primary-500), 0.3);
                        border-radius: 4px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        justify-content: space-between;
                        padding: 0 20px;
                        background-color: var(--c-primary-500);
                        display: flex;
                        align-items: center;
                        box-sizing: border-box;
                        text-transform: uppercase;
                        letter-spacing: 0.7px;
                        margin-top: 30px;
                        color: var(--c-white);
                    }

                    .buy-ground:hover {
                        cursor: pointer;
                        padding-right: 1.2em;
                        transition: padding .3s, background-color 1s;
                        background-color: #0E7340;
                    }

                    .underline {
                        padding-bottom: 70px;
                    }

                    .steps5img {
                        width: 100%;
                    }

                }
            }


            .discover-settlement {
                display: flex;
                justify-content: center;
                background-color: var(--c-black);

                .discover-section-container {
                    position: relative;
                    background-color: var(--c-white);
                    color: var(--c-primary-900);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    width: 1440px;
                    box-sizing: border-box;
                    padding: 80px 80px 0;

                    .pad {
                        padding: 0 30px;
                        display: flex;
                        justify-content: center;

                        .nasz {
                            margin-left: 130px;
                        }

                        .center-title {
                            margin-right: 50px;
                        }

                        .title-discover {
                            font-size: 36px;
                            line-height: 125%;
                            text-align: center;
                            color: var(--c-neutral);
                            margin-top: 30px;
                        }
                    }

                    .contact-title {
                        font-size: 48px;
                        margin-top: 30px;
                        margin-bottom: 50px;
                        padding-right: 180px;
                    }

                    .main-description {
                        color: var(--c-neutral);
                        line-height: 28.8px;
                        padding-right: 100px;
                    }

                    .career-img-grid {
                        padding: 0 0 0 40px;
                    }

                    .career-img-numbers {
                        padding: 20px 0 0 40px;
                    }

                    .buy-ground {
                        height: 54px;
                        width: 330px;
                        border: 0.5px solid rgba(var(--c-primary-500), 0.3);
                        border-radius: 4px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        justify-content: space-between;
                        padding: 0 20px;
                        background-color: var(--c-primary-500);
                        display: flex;
                        align-items: center;
                        box-sizing: border-box;
                        text-transform: uppercase;
                        letter-spacing: 0.7px;
                        margin-top: 30px;
                        color: var(--c-white);
                    }

                    .buy-ground:hover {
                        cursor: pointer;
                        padding-right: 1.2em;
                        transition: padding .3s, background-color 1s;
                        background-color: #0E7340;
                    }

                    .underline {
                        padding-bottom: 70px;
                    }

                    .steps5img {
                        width: 100%;
                    }

                }
            }



        }

    }
}


// https://css-tricks.com/the-many-ways-to-change-an-svg-fill-on-hover-and-when-to-use-them/