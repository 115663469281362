.hb-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .hero-blank-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background-image: url('../assets/images/heroblankbg.png');

        width: 1440px;
        height: 320px;
        box-sizing: border-box;
        padding: 10px 120px 0;

        .hero-title {
            line-height: 130%;
            font-weight: 500;
            font-size: 42px;
        }

        .hero-description {
            width: 600px;
            font-size: 16px;
            line-height: 150%;
        }
    }
}