.smContainer {
    display: flex;
    justify-content: center;
    background-color: black;

    .settlement-section-container {
        background-color: var(--c-white);
        color: var(--c-white);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100vh;
        height: 800px;
        width: 1440px;
        box-sizing: border-box;
        padding: 250px 80px 250px;
        position: relative;

        .photoSs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50%;
            background-color: var(--c-white);
            position: relative;

            .photoFunnel {
                width: 100%;
                height: auto;
            }

            .logoWhiteLeft {
                left: 30px;
                bottom: 20px;
                height: auto;
                width: auto;
            }

            .logoWhiteRight {
                right: 30px;
                bottom: 20px;
                height: auto;
                width: auto;
            }

            .logoWhiteLeft {
                left: 30px;
                bottom: 20px;
                height: auto;
                width: auto;
            }

            .okzLogoWhite {
                right: 30px;
                bottom: 20px;
                height: auto;
                width: auto;
            }

            .vwOsg {
                right: -20px;
                bottom: -20px;
            }

            .vwOwk {
                left: -20px;
                bottom: -20px;
            }

            .vwOsd {
                right: -20px;
                bottom: -20px;
            }
        }

        .descriptionSs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50%;
            background: var(--c-white);

            .content {
                box-sizing: border-box;
                color: black;
                width: 100%;
                padding: 0 80px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-align: start;

                img {
                    vertical-align: text-bottom;
                }

                h1 {
                    // width: 455px;
                    width: 100%;
                    font-size: 56px;
                    font-weight: 400;
                    padding: 0 !important;
                }

                .description {
                    line-height: 150%;
                }

                .btn-primary {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;
                    box-sizing: border-box;
                    color: var(--c-white);
                    background-color: #10854B;
                    border: 0px;
                    border-radius: 4px;
                    width: 221px;
                    height: 52px;
                    font-size: 14px;
                    font-weight: 600;
                }

                .btn-primary:hover {
                    background-color: #0E7340;
                    cursor: pointer;
                    padding-right: 1.2em;
                    transition: padding .3s, background-color .3s;
                }

                .btn-secondary {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;
                    box-sizing: border-box;
                    color: black;
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 4px;
                    width: 221px;
                    height: 52px;
                    font-size: 14px;
                    font-weight: 600;
                }

                .btn-secondary:hover {
                    cursor: pointer;
                    padding-right: 1.2em;
                    transition: padding .3s, background-color .3s;
                }

                .infoContainer {
                    display: flex;
                    width: 100%;

                    .infoBox {
                        width: 50%;

                        .infoLabel {
                            color: #B1B1B1;
                            font-size: 13px;
                            letter-spacing: 0.02em;

                            .oldPrice {
                                color: black;
                                text-decoration: line-through;
                                font-size: 18px;
                                // line-height: 100%;
                            }

                            img {
                                vertical-align: middle;
                                padding-right: 15px;
                            }

                        }

                        .infoSize {
                            color: black;
                            font-size: 32px;
                            font-weight: 400;
                            letter-spacing: 0.06em;
                            margin-left: 45px;
                            line-height: 30px;
                        }

                        .infoPrice {
                            color: var(--c-red);
                            font-size: 31px;
                            font-weight: 400;
                            letter-spacing: 0.06em;
                            margin-left: 50px;
                            line-height: 30px;
                        }
                    }
                }

            }
        }
    }
}


// SVG https://css-tricks.com/the-many-ways-to-change-an-svg-fill-on-hover-and-when-to-use-them/