.err-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .err-section-container {
        position: relative;
        background-color: rgb(243, 249, 253);
        color: #111E18;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 1440px;
        box-sizing: border-box;
        padding: 50px 80px 0;
        height: 405px;

        .error-title {
            color: rgb(223, 42, 74);
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: .01em;
        }

    }
}