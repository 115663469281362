.hs-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .hero-section-container {
        position: relative;
        background-color: var(--c-primary-900);
        background-image: url('../assets/images/pattern.png');
        color: var(--c-white);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1440px;
        box-sizing: border-box;
        padding: 50px 80px 0;

        .left-hs {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: 700px;
            width: 50%;

            .title-hero {
                font-size: 13px;
                letter-spacing: 0.01em;
                color: #10854B;
            }

            .hero-description {
                font-size: 20px;
                line-height: 30px;
                color: rgba(255, 255, 255, 0.7);
                text-align: left;

                .white-text {
                    color: var(--c-white);
                }
            }

            .title-cta-hero {
                font-size: 72px;
                font-weight: 400;
            }

            .btn-check-funnel {
                display: flex;
                align-items: center;
                height: 52px;
                width: 275px;
                border: 0.5px solid rgba(var(--c-primary-500), 0.3);
                border-radius: 4px;
                justify-content: space-between;
                margin-top: 30px;
                padding: 0 20px;
                background-color: var(--c-primary-500);
                box-sizing: border-box;

                .cta-text {
                    font-size: 14px;
                }
            }

            .btn-check-funnel:hover {
                cursor: pointer;
                padding-right: 1.2em;
                transition: padding .3s, background-color 1s;
                background-color: #0E7340;
            }

            .video-placeholder {
                position: absolute;
                bottom: 0;
            }

            .text-placeholder {
                position: absolute;
                bottom: 114px;
                width: 320px;
                height: 96px;
                left: 490px;
                display: flex;
                flex-direction: column;

                .hero-description-settlement {
                    color: rgba(255, 255, 255, 0.6);
                    font-size: 16px;
                    line-height: 160%;
                    letter-spacing: 0.01em;
                    text-align: left;
                }
            }
        }

        .right-hs {
            width: 50%;
            height: 700px;

            .map-container {
                width: 536px;
                height: 508px;
                position: relative;

                &:hover .tooltip-map-info {
                    display: none;
                }

                .tooltip {
                    position: absolute;

                    &:hover .tooltip-text {
                        visibility: visible;
                        opacity: 1;
                        cursor: pointer;
                    }

                    .tooltip-text {
                        visibility: hidden;
                        opacity: 0;
                        transition: visibility 0s, opacity 0.5s linear;
                        width: 120px;
                        background-color: var(--c-primary-500);
                        // border: 1px solid var(--c-black);
                        border-radius: 5px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 300px;
                        height: 60px;
                        color: var(--c-white);
                        text-align: center;
                        padding: 5px 0 5px 10px;
                        position: absolute;
                        top: -85px;
                        left: -153px;
                        z-index: 2;

                        .miniatures-settlement {
                            padding-right: 10px;
                            width: 70px;
                            height: 50px;
                        }

                        .title-settlement {
                            display: flex;

                            font-size: 16px;
                            font-weight: 600;
                            margin: 0;
                            padding-bottom: 10px;
                        }

                        .region-name {
                            display: flex;
                            align-items: center;
                            justify-self: flex-start;
                            font-size: 13px;
                            opacity: .8;
                        }

                        .region-icon {
                            padding-right: 5px;
                        }
                    }

                    &:hover .tooltip-triangle {
                        visibility: visible;
                        opacity: 1;
                        cursor: pointer;
                    }

                    .tooltip-triangle {
                        visibility: hidden;
                        opacity: 0;
                        transition: visibility 0s, opacity 0.5s linear;
                        position: absolute;
                        top: -16px;
                        left: -4px;
                        background: transparent;
                        width: 0px;
                        height: 0px;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid transparent;
                        border-top: 10px solid var(--c-primary-500);
                    }

                    .triangle-inactive {
                        border-top: 10px solid var(--c-secondary-500);
                    }

                    .sold {
                        background-color: var(--c-secondary-500);
                        z-index: 2;
                    }
                }
            }

            .map-svg {
                width: 536px;
                height: 508px;
            }

            .tooltip-map-info {
                position: absolute;
                left: 40%;
                top: 50%;
            }

            .pin {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                position: absolute;

                &:hover {
                    cursor: pointer;
                }
            }

            .active-pin {
                background: #19AB4B;
                z-index: 1;
            }

            .inactive-pin {
                background-color: var(--c-secondary-500);
                z-index: 0;
            }

            .osd-pin {
                left: 160px;
                top: 17px;
            }

            .osd-pin-effect {
                left: 100px;
                top: -27px;
            }

            .ojm-pin {
                left: 278px;
                top: 87px;
            }

            .owk-pin {
                left: 225px;
                top: 78px;
            }

            .ojd-pin {
                left: 135px;
                top: 87px;
            }

            .okz-pin {
                left: 260px;
                top: 123px;
            }

            .olp-pin {
                left: 44px;
                top: 210px;
            }

            .odw-pin {
                left: 65px;
                top: 200px;
            }

            .ole-pin {
                left: 400px;
                top: 210px;
            }

            .ozn-pin {
                left: 420px;
                top: 210px;
            }

            .osb-pin {
                left: 385px;
                top: 250px;
            }

            .ogs-pin {
                left: 110px;
                top: 350px;
            }

            .ogs2-pin {
                left: 120px;
                top: 355px;
            }

            .ozd-pin {
                left: 135px;
                top: 395px;
            }

            .osg-pin {
                left: 130px;
                top: 380px;
            }

            .ogb-pin {
                left: 140px;
                top: 370px;
            }

            .opo-pin {
                left: 370px;
                top: 455px;
            }

            .legend {
                display: flex;
                justify-content: flex-end;
                width: 510px;
                margin-left: 120px;
                margin-top: 40px;

                .legend-info {
                    display: flex;
                    justify-content: space-evenly;
                    list-style-type: none;
                    width: 100%;

                    li:nth-child(odd) {
                        margin-right: -25px;
                    }

                    .pin {
                        width: 13px;
                        height: 13px;
                        border-radius: 50%;
                        margin-right: 15px;
                    }

                    .active-pin-legend {
                        background: #19AB4B;
                    }

                    .inactive-pin-legend {
                        background: #1986D3;
                        ;
                    }

                    .title {
                        color: rgba(255, 255, 255, .6);
                        font-size: 12px;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}


// https://css-tricks.com/the-many-ways-to-change-an-svg-fill-on-hover-and-when-to-use-them/