.settlementrouter-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .about-section-container {
        position: relative;
        background-color: var(--c-white);
        color: #111E18;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        // align-items: stretch;
        width: 1440px;
        box-sizing: border-box;
        padding: 50px 80px 0;
        height: 900px;

        div {
            width: 360px;
        }

        .sr-col {
            display: flex;
            flex-direction: column;
            // background-color: darkgray;

            .title-section {
                font-size: 13px;
                font-weight: 700;
                letter-spacing: .1em;
                line-height: 120%;
                color: rgb(2, 102, 51);
                color: var(--c-primary-500);
                text-transform: uppercase;
                margin-block-start: 0;
            }

            .title-sold {
                color: var(--c-secondary-500);
            }

            .tooltip-container {
                height: 280px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 30px;
                // background-color: blue;

                .tooltip-text {
                    background-color: rgb(229, 240, 234);
                    background-color: rgb(222, 237, 229);
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    height: 60px;
                    border-radius: 4px;
                    text-align: center;
                    padding: 5px 0 5px 10px;

                    &:hover {
                        cursor: pointer;
                        background-color: rgba(229, 240, 234, .7);
                        transition: 500ms;
                    }

                    .miniatures-settlement {
                        padding-right: 15px;
                        width: 70px;
                        height: 50px;
                    }

                    .title-settlement {
                        display: flex;
                        font-size: 14px;
                        font-weight: 600;
                        margin: 0;
                        padding-bottom: 10px;
                        letter-spacing: .5px;
                    }

                    .region-name {
                        display: flex;
                        align-items: center;
                        justify-self: flex-start;
                        font-size: 13px;
                        opacity: .8;
                        // color: #757575;
                        color: var(--c-custom-3);
                    }

                    .region-icon {
                        padding-right: 5px;

                        path {
                            fill: rgb(23, 133, 211) !important;
                        }
                    }

                    .arrow-right {
                        padding: 0 15px 0 0;
                    }
                }

                .tooltip-inactive {
                    background-color: var(--c-secondary-200);
                }
            }

            .quote-container {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 310px;
                margin-top: 30px;

                .quote-img {
                    width: 44px;
                    height: 32px;
                }

                .quote-content {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;
                }
            }

            .pagination {
                height: 35px;
                width: 370px;
                padding-top: 10px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .circle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 43px;
                    height: 43px;
                    border-radius: 50%;
                    background-color: rgb(238, 238, 238);

                    &:hover {
                        cursor: pointer;
                        background-color: rgba(229, 240, 234, .7);
                        transition: 500ms;
                    }

                    .arrow-disabled {
                        opacity: .3;
                    }
                }

                .pagin-number {
                    display: flex;
                    justify-content: center;
                    width: 50px;
                }

            }

            .settlement-cont {
                position: relative;

                .settlement-mask {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    position: absolute;
                    height: 20%;
                    color: var(--c-white);
                    bottom: 30px;
                    left: 20px;

                    .region-name {
                        display: flex;
                        color: #9E9E9E;
                    }

                    .region-icon {
                        padding-right: 5px;

                        path {
                            fill: #9E9E9E;
                        }
                    }

                }
            }
        }




    }
}