.home-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .home-section-container {
        position: relative;
        background-color: var(--c-primary-900);
        background-image: url('../assets/images/pattern.png');
        // background-color: white;
        color: var(--c-white);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 1440px;
        min-height: 500px;
        box-sizing: border-box;
        padding: 50px 80px 0;

        .hero-home {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
}