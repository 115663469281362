.contact-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .contact-section-container {
        position: relative;
        background-color: var(--c-white);
        color: var(--c-primary-900);
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 1440px;
        box-sizing: border-box;

        .contact-left {
            width: 100%;
            height: 100%;
            // height: 900px;
            // background-color: var(--c-custom-1);
            padding: 80px 80px 40px;
            border-right: 1px solid var(--c-custom-2);

            .container {
                position: sticky;
                top: 30px;
                left: 30px;

                .contact-title {
                    font-size: 48px;
                    margin-top: 30px;
                    margin-bottom: 50px;
                    // width: 480px;
                }

            }

            .contact-data {
                padding-bottom: 20px;

                .list-contact {
                    list-style-type: none;
                    padding: 0;
                    padding-top: 10px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 160%;
                    letter-spacing: .5px;

                    li:nth-child(2) {
                        text-align: left;
                        // padding: 5px 0;
                    }
                }

                a {
                    color: var(--c-primary-500) !important;
                    text-transform: uppercase;
                    letter-spacing: .5px;
                    font-size: 13px;
                }
            }

            .social-icons-contact {
                margin-top: 50px;
                display: flex;

                .social-menu-title {
                    display: flex;
                    align-items: center;
                }

                .social-menu {
                    display: flex;
                    list-style-type: none;
                    // width: 25%;

                    .social-element {
                        padding-right: 10px;
                    }

                }

                .social-element-fill {
                    cursor: pointer;
                    fill: var(--c-black);

                    path {
                        fill: var(--c-black);
                        transition: all ease 0.5s;
                    }
                }

                .social-element-fill:hover {
                    cursor: pointer;

                    path {
                        fill: #10854B;
                        transition: all ease 0.5s;
                    }
                }
            }
        }

        .guardians {
            display: flex;
            justify-content: center;
            margin-top: 50px;
        }

        .guardians-list div {
            display: flex;
            justify-content: space-evenly;

            .titleBorder {
                border-bottom: 1px solid var(--c-custom-9);
                margin-bottom: 10px;
                // width: 350px;
                // margin-right: 5px;
            }

            .title {
                color: var(--c-custom-3);
                font-weight: 400;
            }
        }

        .custom-1 {
            width: 0px;
        }


        .guardians-avatar {
            margin-bottom: 50px;

            div {
                display: flex;
                justify-content: space-evenly;

                .titleBorder {
                    // width: 350px;

                    img {
                        margin-right: 5px;
                        margin-top: 5px;

                        &:hover {
                            cursor: pointer;
                            opacity: .8;
                        }
                    }
                }
            }
        }

        .map-contact:hover {
            opacity: .7;
        }
    }
}

// https://www.bryntum.com/blog/3-ways-to-style-radio-buttons-with-modern-css/
// https://www.youtube.com/watch?v=NGvJzWmd904
// https://www.youtube.com/watch?v=zmMJ5DEdz-4