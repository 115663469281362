// https://codepen.io/snowleo208/pen/NLpZjv?editors=1100

.dl-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .dl-section-container {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        width: 1440px;
        min-height: 400px;
        background-color: var(--c-white);
        box-sizing: border-box;
        padding: 60px 120px 100px;

        .dl-category-name {
            font-size: 20px;
            padding: 50px 0 20px;

            &:nth-of-type(1) {
                padding: 0 0 20px;
            }
        }

        $table-header: var(--c-white);
        $table-header-border: #D4DFE5;
        $table-border: #d9d9d9;
        $row-bg: #f4f2f1;

        div {
            box-sizing: border-box;
        }

        .table-container {
            display: block;
            width: 100%;
            max-width: 1200px;
        }

        .flag-icon {
            margin-right: 0.1em;
        }

        .flex-table {
            display: flex;
            flex-flow: row wrap;
            // border-left: solid 1px $table-border;
            transition: 0.5s;

            .first {
                display: flex;
                text-align: start;
            }

            .date,
            .size,
            .filetype {
                color: rgba(0, 0, 0, 0.5);
            }

            &:first-of-type .flex-row {
                background: $table-header;
                color: rgba(0, 0, 0, 0.6);
                // border-color: $table-header-border;
            }

            &:hover {
                background-color: var(--c-error-50);
                transition: 500ms;

            }
        }

        .flex-table .flex-row:nth-last-child(1) {
            color: var(--c-primary-500);
            cursor: pointer;

        }

        // .flex-table .flex-row:nth-last-child(2),
        // .flex-table .flex-row:nth-last-child(3),
        // .flex-table .flex-row:nth-last-child(4) {
        //     opacity: .5;
        // }

        .flex-row {
            // width: calc(100% / 5);
            width: 175px;
            text-align: center;
            padding: 0.9em 0.5em;
            // border-right: solid 1px $table-border;
            border-bottom: solid 1px $table-border;

            &:first-of-type {
                width: 500px;
            }

            .document-icon,
            .download-icon {
                padding-right: 5px;
                text-align: start;
            }

            .document-icon {
                padding-left: 10px;
            }
        }

        .rowspan {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: center;
        }

        .column {
            display: flex;
            flex-flow: column wrap;
            width: 75%;
            padding: 0;

            .flex-row {
                display: flex;
                flex-flow: row wrap;
                width: 100%;
                padding: 0;
                border: 0;
                border-bottom: solid 1px $table-border;

                &:hover {
                    background: #F5F5F5;
                    transition: 500ms;
                }
            }
        }

        .flex-cell {
            width: calc(100% / 3); //1px = border right
            text-align: center;
            padding: 0.5em 0.5em;
            // border-right: solid 1px $table-border;

            //flex: 1 1 33.3%;
            &:last-child {
                // border-right: 0;
            }
        }

        // mobile
        @media all and (max-width: 767px) {
            .flex-row {
                width: calc(100% / 3); //1px = border right

                &.first {
                    width: 100%;
                }
            }

            .column {
                width: 100%;
            }
        }

        @media all and (max-width: 430px) {

            .flex-table {
                .flex-row {
                    border-bottom: 0;
                }

                .flex-row:last-of-type {
                    border-bottom: solid 1px $table-border;
                }
            }

            .header {
                .flex-row {
                    border-bottom: solid 1px;
                }
            }

            .flex-row {
                width: 100%; //1px = border right

                &.first {
                    width: 100%;
                    border-bottom: solid 1px $table-border;
                }
            }

            .column {
                width: 100%;

                .flex-row {
                    border-bottom: solid 1px $table-border;
                }
            }

            .flex-cell {
                width: 100%; //1px = border right
            }
        }
    }
}