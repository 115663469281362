.contact-form {
    height: 100% !important;
    color: var(c-neutral-900);
    background: var(--c-custom-1);
    padding: 56px !important;

    .contact-form-title {
        color: var(--c-black);
        font-weight: 500;
        font-size: 36px;
        line-height: 125%;
    }

    .form-question1 {
        display: flex;
        justify-content: flex-start;
        margin-top: 32px;
    }

    .form-answers1 {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 20px;

        label input {
            margin-right: 7px;
        }
    }

    .form-question2 {
        display: flex;
        justify-content: flex-start;
        margin-top: 32px;
    }

    .form-answers2 {
        display: flex;
        justify-content: space-between;
        width: 30%;
        margin-top: 15px;

        label input {
            margin-right: 7px;
        }
    }

    .inputStyles {
        margin-top: 8px !important;
        padding: 10px;
        width: 100%;
        border: 1px solid var(--c-neutral-400);
        border-radius: 4px;
    }

    .inputAreaStyles {
        min-height: 120px;
        resize: none;
    }

    .btnContact {
        width: 100%;
        min-height: 52px;
        background-color: var(--c-primary-500);
        color: var(--c-white);
        text-transform: uppercase !important;
        border: 1px solid var(--c-primary-500);
        border-radius: 4px !important;
        letter-spacing: 0.03em;
        margin: 10px 0 25px 0;

        &:hover {
            opacity: 0.9;
        }
    }

    .inputDescSmall {
        font-size: 13px;
        margin-bottom: 10px;

        input {
            margin-right: 5px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .additionalInfo {
        color: var(--c-custom-8);

        img {
            float: left;
            padding-right: 10px;
        }

        p {
            padding-top: 3px;
        }
    }

    .form-answers1 {
        cursor: pointer;

        label input {
            border-radius: 25%;
        }
    }

    .radioCheck {
        // color: red;
    }
}


//
//
// https://codepen.io/SupunKavinda/pen/eYNMQNM