.sellground-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .career-section-container {
        position: relative;
        background-color: var(--c-white);
        color: var(--c-primary-900);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 1440px;
        box-sizing: border-box;
        padding: 80px 80px 0;
        // height: 100%;

        .sellground-title {
            font-size: 48px;
            margin-top: 30px;
            margin-bottom: 50px;
            padding-right: 140px;
        }

        .sellground-title-second {
            font-size: 21px;
            line-height: 150%;
        }

        .sellground-title-third {
            margin-top: 50px;
            font-size: 18px;
            line-height: 150%;
            font-weight: 600;
        }

        .sell-ground-point {
            font-size: 16px;
            line-height: 150%;
            font-weight: 400;
        }

        .main-description {
            color: var(--c-neutral);
            line-height: 28.8px;
            padding-right: 100px;
        }

        .sellground-img-grid {
            padding: 0 0 40px 0px;
        }

        .career-img-numbers {
            padding: 20px 0 0 40px;
        }

        .sell-ground {
            height: 54px;
            width: 280px;
            border: 0.5px solid rgba(var(--c-primary-500), 0.3);
            border-radius: 4px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            justify-content: space-between;
            padding: 0 20px;
            background-color: var(--c-primary-500);
            display: flex;
            align-items: center;
            box-sizing: border-box;
            text-transform: uppercase;
            letter-spacing: 0.7px;
            margin-top: 30px;
            color: var(--c-white);
        }

        .sell-ground:hover {
            cursor: pointer;
            padding-right: 1.2em;
            transition: padding .3s, background-color 1s;
            background-color: #0E7340;
        }

        .no-underline {
            padding-bottom: 70px;
            height: 1050px;
        }

    }

    .departments-cont {
        margin: 50px 0;

        .departments-title {
            font-size: 36px;
            line-height: 125%;
            text-align: center;
            color: var(--c-neutral);
        }

        .departments-desc {
            font-size: 18px;
            line-height: 160%;
            text-align: center;
            color: var(--c-neutral);
            padding: 0 240px;
            margin: 30px 0;
        }
    }


}

.sellground-cont {
    display: flex;
    padding: 40px 80px 60px;
    box-sizing: border-box;
    width: 1440px;
    background-color: var(--c-custom-10);

    .sellground-title {
        font-size: 36px;
        line-height: 125%;
        color: var(--c-neutral);
        text-align: center;
        padding: 0 200px;
    }

    .offers-map {
        margin: 0 0 60px;
    }

    .departments-name {
        margin: 30px 0 20px;
    }

    .single-offer-cont {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 110px;
        padding: 24px 40px;
        margin-bottom: 15px;
        background-color: var(--c-white);
        border-left: 2px solid var(--c-custom-11);

        .offer-title {
            font-size: 21px;
            line-height: 150%;
        }

        .parametr-offer {
            width: 80%;
            width: 500px;
            padding-left: 0;
            display: flex;
            list-style: none;
            justify-content: space-between;
            color: var(--c-custom-5)
        }

        .offer-details {
            display: flex;
            align-items: center;
            color: var(--c-primary-500);
            // background-color: lightgray;

            .href-details {
                color: var(--c-primary-500) !important;
                text-decoration: none;
                padding-right: 10px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.sellground-list {
    position: relative;
    height: 300px;
    padding-top: 50px;
    padding-left: 90px;

    .purchase-name {
        position: absolute;
        left: 80px;
        padding-top: 12px;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #111;
    }

    .pn1 {
        top: 50px;
    }

    .pn2 {
        top: 150px;
    }

    .pn3 {
        top: 250px;
    }

    .pn4 {
        top: 350px;
    }

    .title-hero-secondary {
        padding: 0 0 25px 0;
    }

    .lineY {
        position: absolute;
        left: 28px;
        top: 50px;
        height: 300px;
        border-left: 1px solid var(--c-custom-2);
        z-index: 0;
    }

    .dot {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0px;
        height: 56px;
        width: 56px;
        border: 1px solid var(--c-neutral-400);
        border-radius: 50%;
        background-color: var(--c-white);
        z-index: 3;

        .dot-numbers {
            font-size: 18px;
            line-height: 150%;
            color: var(--c-primary-500);
            // z-index: 3;
        }
    }

    .dot1 {
        top: 50px;
    }

    .dot2 {
        top: 150px;
    }

    .dot3 {
        top: 250px;
    }

    .dot4 {
        top: 350px;
    }
}


// ponizej



.sellground-form {
    height: 100% !important;
    color: var(c-neutral-900);
    background: var(--c-custom-1);
    padding-top: 56px !important;

    .inputStyles {
        margin-top: 8px !important;
        margin-bottom: 8px;
        padding: 10px;
        width: 100%;
        border: 1px solid var(--c-neutral-400);
        border-radius: 4px;
    }

    .inputAreaStyles {
        min-height: 120px;
        resize: none;
    }

    .btnContact {
        width: 100%;
        min-height: 52px;
        background-color: var(--c-primary-500);
        color: var(--c-white);
        text-transform: uppercase !important;
        border: 1px solid var(--c-primary-500);
        border-radius: 4px !important;
        letter-spacing: 0.03em;
        margin: 10px 0 25px 0;

        &:hover {
            opacity: 0.9;
        }
    }

    .inputDescSmall {
        font-size: 13px;
        margin-bottom: 10px;

        input {
            margin-right: 5px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .inputDescSmall:nth-child(even) {
        margin-bottom: 20px !important;
    }

    .additionalInfo {
        color: var(--c-custom-8);

        img {
            float: left;
            padding-right: 10px;
        }

        p {
            padding-top: 3px;
        }
    }

    .form-answers1 {
        cursor: pointer;

        label input {
            border-radius: 25%;
        }
    }


    .contact-inf {
        font-size: 13px;
        text-align: center;
        color: var(--c-neutral);
    }
}