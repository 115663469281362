/* @font-face {
  font-family: 'Gilroy';
  src: url('../public/css/fonts/gilroy-medium.otf');
} */

/* GLOBAL STATIC ELEMENT */
* {
  font-family: 'Gilroy' !important;
  text-decoration: none;
  box-sizing: content-box;
  scroll-behavior: smooth;
}

::-webkit-input-placeholder {
  color: var(--c-neutral-400);
}

html {
  background-color: var(--c-black);
}

.zi {
  /* text-align: center; */
}

a {
  text-decoration: none;
  color: white !important;
}

/* GLOBAL VARIABLES */
:root {
  /* colors */
  --c-black: #000000;
  --c-white: #FFFFFF;
  --c-red: #DB4444;
  --c-yellow: #FFDC25;
  --c-turquoise: #7BDEAC;
  --c-neutral: #111111;
  --c-neutral-400: #BDBDBD;
  --c-neutral-900: #212121;
  --c-error-900: #2D080F;
  --c-error-800: #430D16;
  --c-error-700: #86192C;
  --c-error-600: #B2223B;
  --c-error-500: #DF2A4A;
  --c-error-400: #E23F5C;
  --c-error-300: #EC7F92;
  --c-error-200: #F9D4DB;
  --c-error-100: #FCEAED;
  --c-error-50: #FEF9FA;
  --c-warning-900: #320600;
  --c-warning-800: #641700;
  --c-warning-700: #943400;
  --c-warning-600: #DB7500;
  --c-warning-500: #F29100;
  --c-warning-400: #F39C19;
  --c-warning-300: #F7BD66;
  --c-warning-200: #FBDEB2;
  --c-warning-100: #FEF4E5;
  --c-warning-50: #FFFCF7;
  --c-success-900: #020E09;
  --c-success-800: #031C11;
  --c-success-700: #07452B;
  --c-success-600: #0C6E45;
  --c-success-500: #0F8A56;
  --c-success-400: #3FA178;
  --c-success-300: #6FB99A;
  --c-success-200: #B7DCCC;
  --c-success-100: #E7F3EE;
  --c-success-50: #F8FBFA;
  --c-primary-900: #001F0F;
  --c-primary-800: #002914;
  --c-primary-700: #003D1F;
  --c-primary-600: #005229;
  --c-primary-500: #006633;
  --c-primary-400: #B2D1C2;
  --c-primary--300: #CCE0D6;
  --c-primary--200: #E5F0EB;
  --c-primary--100: #F2F7F5;
  --c-primary--50: #F7FAF9;
  --c-secondary-900: #07283F;
  --c-secondary-800: #0A3554;
  --c-secondary-700: #0E507F;
  --c-secondary-600: #136AA9;
  --c-secondary-500: #1885D3;
  --c-secondary-400: #BADAF2;
  --c-secondary-300: #D1E7F6;
  --c-secondary-200: #E8F3FB;
  --c-secondary-100: #F3F9FD;
  --c-secondary-50: #F8FBFE;
  --c-custom-1: #F1F7FC;
  --c-custom-2: #0B5527;
  --c-custom-3: #757575;
  --c-custom-4: #D1D8DB;
  --c-custom-5: #616161;
  --c-custom-6: #DADADA;
  --c-custom-7: #A4C7B5;
  --c-custom-8: #626262;
  --c-custom-9: #d9d9d9;
  --c-custom-10: #f0f8fd;
  --c-custom-11: #0b5526;

}

/* GLOBAL CLASSNAMES */
.cAbsolute {
  position: absolute;
}