.dc-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .dc-content-container {
        background-color: var(--c-white);
        color: var(--c-primary-900);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 1440px;
        box-sizing: border-box;
        padding: 50px 120px 0;
        min-height: 920px;
    }
}