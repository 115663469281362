.career-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .career-section-container {
        position: relative;
        background-color: var(--c-white);
        color: var(--c-primary-900);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 1440px;
        box-sizing: border-box;
        padding: 80px 80px 0;
        // height: 100%;

        .contact-title {
            font-size: 48px;
            margin-top: 30px;
            margin-bottom: 50px;
            padding-right: 180px;
        }

        .main-description {
            color: var(--c-neutral);
            line-height: 28.8px;
            padding-right: 100px;
        }

        .career-img-grid {
            padding: 0 0 0 40px;
        }

        .career-img-numbers {
            padding: 20px 0 0 40px;
        }

        .buy-ground {
            height: 54px;
            width: 330px;
            border: 0.5px solid rgba(var(--c-primary-500), 0.3);
            border-radius: 4px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            justify-content: space-between;
            padding: 0 20px;
            background-color: var(--c-primary-500);
            display: flex;
            align-items: center;
            box-sizing: border-box;
            text-transform: uppercase;
            letter-spacing: 0.7px;
            margin-top: 30px;
            color: var(--c-white);
        }

        .buy-ground:hover {
            cursor: pointer;
            padding-right: 1.2em;
            transition: padding .3s, background-color 1s;
            background-color: #0E7340;
        }

        .underline {
            padding-bottom: 70px;
            border-bottom: 1px solid var(--c-custom-9);
        }

    }

    .departments-cont {
        margin: 50px 0;

        .departments-title {
            font-size: 36px;
            line-height: 125%;
            text-align: center;
            color: var(--c-neutral);
            margin-top: 30px;
        }

        .departments-desc {
            font-size: 18px;
            line-height: 160%;
            text-align: center;
            color: var(--c-neutral);
            padding: 0 240px;
            margin: 30px 0 50px;
        }
    }


}

.offers-cont {
    display: flex;
    padding: 40px 80px 60px;
    box-sizing: border-box;
    width: 1440px;
    background-color: var(--c-custom-10);

    .offers-title {
        font-size: 36px;
        line-height: 125%;
        color: var(--c-neutral);
    }

    .no-jobs {
        margin: 50px 0;
    }

    .offers-map {
        margin: 0 0 60px;
    }

    .departments-name {
        margin: 30px 0 20px;
    }

    .single-offer-cont {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 110px;
        padding: 24px 40px;
        margin-bottom: 15px;
        background-color: var(--c-white);
        border-left: 2px solid var(--c-custom-11);

        .offer-title {
            font-size: 21px;
            line-height: 150%;
        }

        .parametr-offer {
            width: 80%;
            width: 500px;
            padding-left: 0;
            display: flex;
            list-style: none;
            justify-content: space-between;
            color: var(--c-custom-5)
        }

        .offer-details {
            display: flex;
            align-items: center;
            color: var(--c-primary-500);
            // background-color: lightgray;

            .href-details {
                color: var(--c-primary-500) !important;
                text-decoration: none;
                padding-right: 10px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

// accordion test
.accordion {
    background-color: var(--c-custom-10);
    border-radius: 4px;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    // width: 50%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 18px;
    transition: 0.4;
    margin-bottom: 10px;

    display: flex;
    align-items: center;


    .boxOffice {
        margin-right: 15px;
    }

    .arrowCareer {
        height: 18px;
        margin-right: 10px;
    }

    .title {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }
}

.active {
    color: var(--c-primary-500);

    .arrowCareer {
        content: url("../assets/images/shapes/arrowBottomGreen.png");
        height: 12px;
        margin-right: 10px;
    }
}

.active,
.accordion:hover {
    // background-color: var(--c-custom-10);
}

.panel {
    padding: 10px 18px 0;
    display: none;
    background-color: var(--c-custom-10);
    overflow: hidden;
    font-size: 16px;
    line-height: 160%;
    color: #424242;
    margin: -10px 0 10px;
    // &:hover {
    //     cursor: pointer;
    // }
}