.footer-class {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);
    // width: 100%;

    .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 425px;
        background-color: var(--c-white);
        background-image: url('../assets/images/bg-footer-group.png') !important;
        background-repeat: no-repeat;
        background-position: center;
        color: var(--c-white);
        width: 1440px;
        box-sizing: border-box;
        padding: 15px 30px 0;

        .first-menu {
            display: flex;
            justify-content: space-between;
            width: 100%;
            // width: 1440px;
            align-items: flex-start;
            padding-bottom: 50px;

            .logo {
                margin-block-start: 1em;
            }

            .biuro-menu {
                list-style-type: none;
                display: flex;
                flex-direction: column;
                align-items: start;
                line-height: 1.6em;

                .title {
                    font-size: 13px;
                    font-weight: bold;
                    color: rgb(115, 114, 115);
                    padding-bottom: 10px;
                    letter-spacing: 0.06em;
                }

                .under-link {
                    position: relative;
                }

                .under-link:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 1px;
                    bottom: -3px;
                    left: 0;
                    background-color: var(--c-white);
                    transition: transform 0.25s ease-out;
                }

                .under-link:hover:after {
                    transform: scaleX(1);
                }
            }
        }

        .second-menu {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // width: 1440px;
            width: 100%;
            align-items: flex-start;
            font-size: 14px;
            letter-spacing: 0.04em;
            opacity: .9;
            padding-bottom: 30px;


            .links-menu {
                display: flex;
                align-items: center;
                list-style-type: none;
                width: 100%;
                height: 36px;
                padding-left: 0px;

                .link-element {
                    padding-right: 25px;

                    .under-link {
                        position: relative;
                    }

                    .under-link:after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        transform: scaleX(0);
                        height: 1px;
                        bottom: -3px;
                        left: 0;
                        background-color: var(--c-white);
                        transition: transform 0.25s ease-out;
                    }

                    .under-link:hover:after {
                        transform: scaleX(1);
                    }
                }
            }

            .social-menu {
                display: flex;
                justify-content: flex-end;
                list-style-type: none;
                // width: 25%;

                .social-element {
                    padding-right: 10px;
                }

            }
        }

        .third-menu {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // width: 1440px;
            width: 100%;
            align-items: flex-end;

            .links-menu {
                display: flex;
                align-items: center;
                list-style-type: none;
                width: 100%;
                padding-left: 0px;

                .copyright {
                    color: rgba(255, 255, 255, .4);
                }

                .policy .policy-link {
                    color: rgba(255, 255, 255, .4);
                }

                .policy .policy-link:hover {
                    color: var(--c-white);
                    cursor: pointer;
                }

                .copyright {
                    padding-right: 20px;
                }
            }

            .social-menu {
                display: flex;
                justify-content: flex-end;
                list-style-type: none;
                width: 25%;

                .design {
                    color: rgba(255, 255, 255, .4);

                    .design-owner {
                        color: rgba(255, 255, 255, .4);
                    }

                    .design-owner:hover {
                        color: var(--c-white);
                        cursor: pointer;
                    }
                }
            }

        }

        .buy-ground {
            // height: 48px;
            // width: 222px;
            width: 198px;
            height: 41px;
            border: 0.5px solid rgba(var(--c-primary-500), 0.3);
            border-radius: 4px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            margin-left: 40px;
            justify-content: space-between;
            padding: 0 20px;
            background-color: var(--c-primary-500);
            display: flex;
            align-items: center;
            box-sizing: border-box;
            margin-block-start: 1em;
            font-size: 13px;
            letter-spacing: 0.06em;
        }

        .buy-ground:hover {
            cursor: pointer;
            // padding-right: .1px;
            transition: padding .3s, background-color 1s;
            background-color: #0E7340;
        }
    }
}

.social-element-fill:hover {
    cursor: pointer;

    path {
        fill: #10854B;
        transition: all ease 0.5s;
    }
}