.article-container {
    display: flex;
    justify-content: center;
    background-color: var(--c-black);

    .article-section-container {
        position: relative;
        background-color: var(--c-white);
        color: var(--c-primary-900);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 1440px;
        box-sizing: border-box;
        padding: 50px 80px 0;
        height: 720px;

    }
}